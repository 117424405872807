const appInitialState = {
  isLoaded: false,
  version: 1.0,
};

const appReducer = (state = appInitialState, action) => {
  switch (action.type) {
    case 'SET_APP_LOADED':
      return { ...state, isLoaded: true };
    default:
      return state;
  }
};

export default appReducer;
