const footerInitialState = {
  img: '',
};

const footerReducer = (state = footerInitialState, action) => {
  switch (action.type) {
    case 'SET_FOOTER_DATA':
      return { ...state, preContent: action.preContent };
    case 'RESET_FOOTER_DATA':
      return { ...state, preContent: null };
    default:
      return state;
  }
};

export default footerReducer;
