module.exports = [{
      plugin: require('../plugins/gatsby-plugin-redux/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alacaza.fr","short_name":"Alacaza","start_url":"/?utm_source=web&utm_medium=icon&utm_campaign=pwa","background_color":"#8782a6","theme_color":"#ffffff","display":"browser","icon":"src/assets/img/alacaza-icon-colored@3x.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7270fe77f1c906c240a7f743a3b87dd1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://74f403382cba470899b211b36150fb6f@o384568.ingest.sentry.io/5435054","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-83794835-6","head":false,"anonymize":false,"pageTransitionDelay":0,"siteSpeedSampleRate":10,"respectDNT":false,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-83794835-6","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"pixelId":"","cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"hjid":"","hjsv":"YOUR_HOTJAR_SNIPPET_VERSION","cookieName":"gatsby-gdpr-hotjar"},"linkedin":{"trackingId":"","cookieName":"gatsby-gdpr-linked-in"},"environments":["production","staging","development"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
